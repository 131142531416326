import { useState } from 'react';
import createStore from 'ctx-provider';

const TRANSACTION_ERROR = false;

const useError = () => {
	const [transactionError, setTransactionError] = useState(TRANSACTION_ERROR);
	const [errorMessage, setErrorMessage] = useState('');

	return {
		transactionError,
		setTransactionError,
		errorMessage,
		setErrorMessage,
	};
};

const store = createStore(useError);

export const { Provider } = store;
export default store.ctx;
