import EnergiCalls from 'utils/calls/energi';
import EthereumCalls from 'utils/calls/ethereum';
import {
	ENV_ENERGI_CHAINID,
	ENV_ETHEREUM_CHAINID,
} from 'utils/constants/chains';

export default {
	[ENV_ENERGI_CHAINID]: EnergiCalls,
	[ENV_ETHEREUM_CHAINID]: EthereumCalls,
};
