import { createTheme } from '@energi/ui';
import defaultTheme from './default-theme';
import colors from './colors';

export default createTheme({
	...defaultTheme,
	palette: {
		...colors,
		type: 'dark',
		text: {
			primary: '#FFFFFF',
			secondary: '#E5E6E7',
		},
		primary: {
			light: colors.green[100],
			main: colors.green[500],
			dark: colors.green[900],
			contrastText: colors.white,
		},
		secondary: {
			light: colors.grey[100],
			main: colors.grey[500],
			dark: colors.grey[900],
			contrastText: colors.white,
		},
		error: {
			light: colors.red[400],
			main: colors.red[600],
			dark: colors.red[800],
			contrastText: colors.white,
		},
		// warning: {
		// 	light: colors.orange[300],
		// 	main: colors.orange[500],
		// 	dark: colors.orange[700],
		// 	contrastText: colors.white,
		// },
		// // Use defaults for info
		// // info: {},
		// success: {
		// 	light: colors.green[100],
		// 	main: colors.green[300],
		// 	dark: colors.green[500],
		// 	contrastText: colors.white,
		// },
		default: {
			light: colors.grey[100],
			main: colors.grey[300],
			dark: colors.grey[400],
			contrastText: 'red',
		},
	},
	overrides: {
		MuiIconButton: {
			root: {
				borderRadius: 6,
				'& .MuiTouchRipple-root *': {
					borderRadius: 6,
				},
			},
		},
		MuiOutlinedInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[800]} inset`,
					WebkitTextFillColor: colors.white,
				},
			},
		},
		MuiFilledInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[100]} inset`,
					WebkitTextFillColor: colors.grey[900],
				},
			},
		},
	},
});
