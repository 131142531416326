import assets from '@energi/energiswap-default-token-list';
import sdk from '@energi/energi-sdk';
import NRGLogo from 'assets/tokens/NRG.svg';

const ENERGI_MAINNET_CHAINID = 39797;
const mainnetTokens = assets.tokens
	.filter(item => item.chainId === ENERGI_MAINNET_CHAINID)
	.map(item => ({
		address: item.address,
		icon: item.logo,
	}));

export default [
	// NRG - native coin - in native Token List (0), name, symbol, and decimals not available on chain
	// used as dummy address - native coin is not a smart contract
	{
		address: sdk.ZERO_ADDRESS,
		icon: NRGLogo,
		name: 'Energi',
		symbol: 'NRG',
		decimals: 18,
	},
	...mainnetTokens,
];
