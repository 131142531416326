import sdk from '@energi/energi-sdk';
import { ENV_ENERGI_CHAINID } from 'utils/constants/chains';

const {
	ZERO_ADDRESS,
	contracts: {
		TOKEN_CASHIER_ADDRESSES,
		NATIVE_TOKEN_LIST_ADDRESSES,
		BRIDGED_TOKEN_LIST_ADDRESSES,
	},
	energi: { WRAPPED_NRG_MAINNET, WRAPPED_NRG_TESTNET },
} = sdk;

/**
 * @developer this method wrappedEnergi(), will be called just once when the page initially loads unless there is a change in chainId.
 * @param {*} chainId
 * @returns the wrapped Energi token
 */
const wrappedEnergi = chainId => {
	const wrapperEnergi = {
		39797: WRAPPED_NRG_TESTNET,
		49797: WRAPPED_NRG_MAINNET,
	};
	if (!wrapperEnergi[chainId]) return null;
	return wrapperEnergi[chainId];
};

export default {
	/**
	 * @developer this method handles symbol, name and type call creation for all Ethereum tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @param {*} type
	 * @returns call
	 */
	constructOtherCalls: (token, type) => {
		const types = {
			name: 'name()(string)',
			symbol: 'symbol()(string)',
			decimals: 'decimals()(uint8)',
		};

		return {
			target: token.address,
			call: [types[type]],
			returns: [[token.address, val => val]],
		};
	},
	/**
	 * @developer this method constructEnergiBridgeMinCall() handles minAmount() call creation for all Energi tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	bridgeMinCall: token => {
		const tokenAddress = token.address;

		let tokenListContractAddress;
		if (
			tokenAddress === ZERO_ADDRESS ||
			tokenAddress === wrappedEnergi(ENV_ENERGI_CHAINID)
		) {
			tokenListContractAddress =
				NATIVE_TOKEN_LIST_ADDRESSES[ENV_ENERGI_CHAINID];
		} else {
			tokenListContractAddress =
				BRIDGED_TOKEN_LIST_ADDRESSES[ENV_ENERGI_CHAINID];
		}

		return {
			target: tokenListContractAddress,
			call: ['minAmount(address)(uint256)', tokenAddress],
			returns: [[tokenAddress, val => val]],
		};
	},
	/**
	 * @developer this method constructEnergiBridgeMaxCall() handles maxAmount() call creation for all Energi tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	bridgeMaxCall: token => {
		const tokenAddress = token.address;

		let tokenListContractAddress;
		if (
			tokenAddress === ZERO_ADDRESS ||
			tokenAddress === wrappedEnergi(ENV_ENERGI_CHAINID)
		) {
			tokenListContractAddress =
				NATIVE_TOKEN_LIST_ADDRESSES[ENV_ENERGI_CHAINID];
		} else {
			tokenListContractAddress =
				BRIDGED_TOKEN_LIST_ADDRESSES[ENV_ENERGI_CHAINID];
		}

		return {
			target: tokenListContractAddress,
			call: ['maxAmount(address)(uint256)', tokenAddress],
			returns: [[tokenAddress, val => val]],
		};
	},
	/**
	 * @developer this method handles allowance() call creation for all Energi tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	allowanceCall: (token, address) => {
		const tokenAddress = token.address;

		return {
			target: tokenAddress,
			call: [
				'allowance(address,address)(uint256)',
				address,
				TOKEN_CASHIER_ADDRESSES[ENV_ENERGI_CHAINID],
			],
			returns: [[tokenAddress, val => val]],
		};
	},
	/**
	 * @developer this method handles balanceOf() call creation for all Energi tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	balanceCall: (token, address) => {
		const tokenAddress = token.address;
		const energiCheckBalance =
			token.address === ZERO_ADDRESS
				? ['getNrgBalance(address)(uint256)', address]
				: ['balanceOf(address)(uint256)', address];

		if (tokenAddress === ZERO_ADDRESS) {
			return {
				call: energiCheckBalance,
				returns: [[tokenAddress, val => val]],
			};
		}

		return {
			target: tokenAddress,
			call: energiCheckBalance,
			returns: [[tokenAddress, val => val]],
		};
	},
};
