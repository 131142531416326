const colors = {
	black: '#000000',
	white: '#FFFFFF',
	grey: {
		50: '#fafbfc',
		100: '#BEC2C3',
		200: '#93999C',
		300: '#e3e4e6',
		400: '#c1c2c3',
		500: '#a2a3a5',
		600: '#797a7b',
		700: '#656667',
		800: '#3A3B3C',
		900: '#242526', // Base
		1000: '#212121',
		1001: '#93999C',
		1002: '#18191A',
		1003: '#454545',
		1005: '#353535',
	},
	green: {
		50: '#E0FCEF',
		100: '#B3F8D6',
		200: '#80F3BB',
		300: '#4DEE9F', // Base
		400: '#26EA8B',
		500: '#00E676',
		600: '#00E36E',
		700: '#00DF63',
		800: '#00DB59',
		900: '#45BD62',
	},
	red: {
		50: '#FFEBF0',
		100: '#FFCDD7',
		200: '#FC98A0',
		300: '#F86D7A',
		400: '#FF4258',
		500: '#FF1E3D',
		600: '#FF0C3E', // Base
		700: '#EC0036',
		800: '#E0002E',
		900: '#DE3F4F',
	},
	orange: {
		50: '#FFF3E0',
		100: '#FFE0B2',
		200: '#FFCD80',
		300: '#FFB84D',
		400: '#FFB343',
		500: '#FF9900', // BASE
		600: '#FB8D00',
		700: '#F57D00',
		800: '#EF6D00',
		900: '#E65200',
	},
	blue: {
		100: '#70B0FF',
		500: '#2A81EA',
		900: '#0055B7',
	},
	yellow: {
		400: '#FFE574',
		500: '#DDC352',
	},
};

export default colors;
