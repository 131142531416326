import createStore from 'ctx-provider';
import { useState } from 'react';
import {
	ENV_ETHEREUM_CHAINID,
	ENV_ENERGI_CHAINID,
	SUPPORTED_NETWORK,
} from 'utils/constants/chains';

const useLatestBlock = () => {
	const [eth, setEth] = useState(0);
	const [nrg, setNrg] = useState(0);

	return {
		latestBlockMethod: {
			[ENV_ENERGI_CHAINID]: setNrg,
			[ENV_ETHEREUM_CHAINID]: setEth,
		},
		latestBlock: {
			[SUPPORTED_NETWORK.ENERGI]: nrg,
			[SUPPORTED_NETWORK.ETHEREUM]: eth,
			[ENV_ENERGI_CHAINID]: nrg,
			[ENV_ETHEREUM_CHAINID]: eth,
		},
	};
};

const store = createStore(useLatestBlock);

export const { Provider } = store;
export default store.ctx;
