import React from 'react';
import PropTypes from 'prop-types';
import { CombinedProviders } from 'ctx-provider';
import { SnackbarProvider } from 'notistack';
import { Provider as MenuProvider } from 'context/menu';
import { Provider as ModalsProvider } from 'context/modals';
import { Provider as PageProvider } from 'context/page';
import { Provider as DarkModeProvider } from 'context/darkmode';
import { Provider as ErrorProvider } from 'context/error';
import { Provider as TokenListProvider } from 'context/tokenlist';
import { Provider as LatestBlock } from 'context/latestBlock';
import { Provider as NetworkProvider } from 'context/network';
import { Provider as TransactionsProvider } from 'context/transactions';
import { Provider as LocalTransactionsProvider } from 'context/localTransaction';
import { Provider as Chains } from 'context/chains';

const GlobalContext = ({ pageProps, children }) => {
	const providers = [
		SnackbarProvider,
		{
			provider: PageProvider,
			args: pageProps,
		},
		Chains,
		MenuProvider,
		ModalsProvider,
		LatestBlock,
		TokenListProvider,
		NetworkProvider,
		DarkModeProvider,
		ErrorProvider,
		LatestBlock,
		TransactionsProvider,
		LocalTransactionsProvider,
	];

	return (
		<CombinedProviders providers={providers}>{children}</CombinedProviders>
	);
};

GlobalContext.propTypes = {
	pageProps: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

export default GlobalContext;
