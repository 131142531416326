import Web3 from 'web3';
import sdk from '@energi/energi-sdk';
import TOKEN_CASHIER_ABI from 'utils/constants/abi/TokenCashier.json';
import TOKEN_CASHIER_GOVERNED_PROXY_ABI from 'utils/constants/abi/TokenCashierGovernedProxy.json';
import TRANSFER_VALIDATOR_ABI from 'utils/constants/abi/TransferValidator.json';
import TOKEN_LIST_ABI from 'utils/constants/abi/TokenList.json';
import ERC20_ABI from 'utils/constants/abi/ERC20.json';

const {
	contracts: {
		TOKEN_CASHIER_ADDRESSES,
		TRANSFER_VALIDATOR_ADDRESSES,
		NATIVE_TOKEN_LIST_ADDRESSES,
		BRIDGED_TOKEN_LIST_ADDRESSES,
	},
	urls: { RPC_URLS },
} = sdk;

const EXPLORER_PREFIXES = {
	39797: 'explorer',
	49797: 'explorer.test',
	42: 'kovan.etherscan',
	4: 'rinkeby.etherscan',
	1: 'etherscan',
};

const EXPLORER_SUFFIXES = {
	39797: 'energi.network',
	49797: 'energi.network',
	42: 'io',
	4: 'io',
	1: 'io',
};

const CACHED_CONTRACTS = {};

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
	try {
		return Web3.utils.isAddress(value);
	} catch {
		return false;
	}
}

export function getExplorerLink(chainId, data, type) {
	const prefix = `https://${
		EXPLORER_PREFIXES[chainId] || EXPLORER_PREFIXES[39797]
	}.${EXPLORER_SUFFIXES[chainId] || EXPLORER_SUFFIXES[39797]}`;

	switch (type) {
		case 'transaction':
			return `${prefix}/tx/${data}`;
		case 'token':
			return `${prefix}/tokens/${data}`;
		case 'address':
		default:
			return `${prefix}/address/${data}`;
	}
}

// account is optional
export function getContract(chainId, address, ABI, salt = '') {
	// if (!isAddress(address) || address === AddressZero) {
	if (!isAddress(address)) {
		throw Error(`Invalid 'address' parameter '${address}'.`);
	}
	try {
		const key = `${chainId}#${address}#${salt}`;
		if (!CACHED_CONTRACTS[key]) {
			const web3 = new Web3(RPC_URLS[chainId]);
			CACHED_CONTRACTS[key] = new web3.eth.Contract(ABI, address);
		}
		return CACHED_CONTRACTS[key];
	} catch (err) {
		throw new Error(err);
	}
}

// account is optional
export function getCashierContract(chainId) {
	return getContract(
		chainId,
		TOKEN_CASHIER_ADDRESSES[chainId],
		TOKEN_CASHIER_ABI,
		'cashier',
	);
}

export function getCashierGovernedProxyContract(chainId) {
	return getContract(
		chainId,
		TOKEN_CASHIER_ADDRESSES[chainId],
		TOKEN_CASHIER_GOVERNED_PROXY_ABI,
		'cashier_proxy',
	);
}

export function getTransferValidatorContract(chainId) {
	return getContract(
		chainId,
		TRANSFER_VALIDATOR_ADDRESSES[chainId],
		TRANSFER_VALIDATOR_ABI,
		'validator',
	);
}

export function getTokenlistContract(chainId, nativeCoin) {
	return getContract(
		chainId,
		nativeCoin
			? NATIVE_TOKEN_LIST_ADDRESSES[chainId]
			: BRIDGED_TOKEN_LIST_ADDRESSES[chainId],
		TOKEN_LIST_ABI,
		'token_list',
	);
}

export function getTokenContract(chainId, tokenAddress) {
	return getContract(chainId, tokenAddress, ERC20_ABI, 'token');
}
