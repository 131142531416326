import { useEffect } from 'react';
import createStore from 'ctx-provider';
import { useToggle } from 'react-use';

const IS_BROWSER = typeof window !== 'undefined';
const useNetwork = () => {
	const [online, isOnline] = useToggle(IS_BROWSER && window.navigator.onLine);

	const setOnline = () => {
		isOnline(true);
	};
	const setOffline = () => {
		isOnline(false);
	};

	useEffect(() => {
		window.addEventListener('offline', setOffline);
		window.addEventListener('online', setOnline);

		// cleanup if we unmount
		return () => {
			window.removeEventListener('offline', setOffline);
			window.removeEventListener('online', setOnline);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { online };
};

const store = createStore(useNetwork);
export const { Provider } = store;
export default store.ctx;
