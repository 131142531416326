import { makeStyles, alpha } from '@energi/ui';

const globalStyles = theme => ({
	h1: {
		...theme.typography.h1,
		margin: 0,
	},
	h2: {
		...theme.typography.h2,
		margin: 0,
	},
	h3: {
		...theme.typography.h3,
		margin: 0,
	},
	h4: {
		...theme.typography.h4,
		margin: 0,
	},
	h5: {
		...theme.typography.h5,
		margin: 0,
	},
	h6: {
		...theme.typography.h6,
		margin: 0,
	},
	'.MuiFilledInput-root, .MuiFilledInput-root.Mui-focused': {
		backgroundColor: theme.palette.grey[50],
		borderRadius: theme.spacing(0.5),
	},
	'.MuiFilledInput-root:hover': {
		backgroundColor: theme.palette.grey[100],
	},
	'.MuiFilledInput-underline:before, .MuiFilledInput-underline:hover:before': {
		border: 'none',
	} /* scrollbars */,
	'::-webkit-scrollbar': {
		width: '0.31rem',
		height: '0.31rem',
	},
	'::-webkit-scrollbar-thumb': {
		WebkitTransition: '0.3s ease all',
		transition: '0.3s ease all',
		borderColor: 'transparent',
		backgroundColor: alpha(theme.palette.common.white, 0.45),
		borderRadius: 3,
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.35),
		},
	} /* END scrollbars */,
});

export default makeStyles(theme => ({
	'@global': globalStyles(theme),
	root: () => {},
}));
