import sdk from '@energi/energi-sdk';
import Web3 from 'web3';
import { ENV_ETHEREUM_CHAINID } from 'utils/constants/chains';

const {
	ZERO_ADDRESS,
	contracts: {
		NATIVE_TOKEN_LIST_ADDRESSES,
		TOKEN_CASHIER_ADDRESSES,
		BRIDGED_TOKEN_LIST_ADDRESSES,
	},
	ethereum: { NRGE_MAINNET, NRGE_TESTNET, DFORCE_ADDRESS, MAKERDAO_ADDRESS },
} = sdk;

export default {
	/**
	 * @developer this method handles symbol, name and type call creation for all Ethereum tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @param {*} type
	 * @returns call
	 */
	constructOtherCalls: (token, type) => {
		const tokenAddress = token.address;
		const types = {
			name: 'name()(string)',
			symbol: 'symbol()(string)',
			decimals: 'decimals()(uint8)',
		};

		let callValue = [types[type]];
		let returnedValue = [[tokenAddress, val => val]];

		if (
			tokenAddress === MAKERDAO_ADDRESS || // MakerDAO on Ethereum Mainnet
			tokenAddress === DFORCE_ADDRESS // dForce on Ethereum Mainnet
		) {
			const exceptionalTypes = {
				name: 'name()(bytes32)',
				symbol: 'symbol()(bytes32)',
				decimals: 'decimals()(uint8)',
			};
			callValue = [exceptionalTypes[type]];
			if (type === 'name' || type === 'symbol') {
				returnedValue = [
					[tokenAddress, val => Web3.utils.hexToAscii(val)], // this exception only occurs on Ethereum Mainnet
				];
			} else if (type === 'decimals') {
				returnedValue = [[tokenAddress, val => val]];
			}
		}

		return {
			target: tokenAddress,
			call: callValue,
			returns: returnedValue,
		};
	},
	/**
	 * @developer this method constructEthereumBridgeMinCall() handles minAmount() call creation for all Ethereum tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	bridgeMinCall: token => {
		const tokenAddress = token.address;
		let tokenListContractAddress;
		if (
			tokenAddress === NRGE_TESTNET || // NRGE on Rinkeby
			tokenAddress === NRGE_MAINNET // NRGE on Ethereum Mainnet
		) {
			tokenListContractAddress =
				BRIDGED_TOKEN_LIST_ADDRESSES[ENV_ETHEREUM_CHAINID]; // 0x1F5a3e8E9D7093CbEAF55849abb7DA362705Be7a
		} else {
			tokenListContractAddress =
				NATIVE_TOKEN_LIST_ADDRESSES[ENV_ETHEREUM_CHAINID]; // 0xdC4A56B75b8A96451B84090855FB5275E9E9ce32
		}

		return {
			target: tokenListContractAddress,
			call: ['minAmount(address)(uint256)', tokenAddress],
			returns: [[tokenAddress, val => val]],
		};
	},
	/**
	 * @developer this method constructEthereumBridgeMaxCall() handles maxAmount() call creation for all Ethereum tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	bridgeMaxCall: token => {
		const tokenAddress = token.address;

		let tokenListContractAddress;
		if (
			tokenAddress === NRGE_MAINNET || // NRGE on Ethereum Mainnet
			tokenAddress === NRGE_TESTNET // NRGE on Rinkeby
		) {
			tokenListContractAddress =
				BRIDGED_TOKEN_LIST_ADDRESSES[ENV_ETHEREUM_CHAINID];
		} else {
			tokenListContractAddress =
				NATIVE_TOKEN_LIST_ADDRESSES[ENV_ETHEREUM_CHAINID];
		}

		return {
			target: tokenListContractAddress,
			call: ['maxAmount(address)(uint256)', tokenAddress],
			returns: [[tokenAddress, val => val]],
		};
	},
	/**
	 * @developer this method handles allowance() call creation for all Ethereum tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	allowanceCall: (token, address) => {
		const tokenAddress = token.address;

		return {
			target: tokenAddress,
			call: [
				'allowance(address,address)(uint256)',
				address,
				TOKEN_CASHIER_ADDRESSES[ENV_ETHEREUM_CHAINID],
			],
			returns: [[tokenAddress, val => val]],
		};
	},
	/**
	 * @developer this method handles balanceOf() call creation for all Ethereum tokens to be sent to the aggregate method of the multicall contract
	 * @param {*} token
	 * @returns call
	 */
	balanceCall: (token, address) => {
		const tokenAddress = token.address;

		const ethereumCheckBalance =
			tokenAddress === ZERO_ADDRESS
				? ['getEthBalance(address)(uint256)', address]
				: ['balanceOf(address)(uint256)', address];

		if (tokenAddress === ZERO_ADDRESS) {
			return {
				call: ethereumCheckBalance,
				returns: [[tokenAddress, val => val]],
			};
		}

		return {
			target: tokenAddress,
			call: ethereumCheckBalance,
			returns: [[tokenAddress, val => val]],
		};
	},
};
