import { useIntl as useIntlDefault } from 'gatsby-plugin-intl';
import { formatBN, translate } from '@energi/utils';

// eslint-disable-next-line import/prefer-default-export
export const expand = items => {
	const result = {};
	items.forEach(({ keys, value }) => {
		keys.forEach(key => {
			result[key] = value;
		});
	});
	return result;
};

export const formatAmountBN = (BN, options) => {
	return formatBN(BN, options).replace(',', '');
};

export const formatBNWithSignificant = (BN, options) => {
	const formatted = formatBN(BN, options);
	if (formatted.includes('.')) {
		const [leading, trailing] = formatted.split('.');
		return `${leading}.${trailing.substring(0, options.significant)}`;
	}
	return formatted;
};

export const formatDecimals = amount => {
	return amount >= 6 ? 6 : amount;
};

export const toFixedDecimals = (amount, length) => {
	let stringAmount = amount;
	if (typeof amount === 'number') {
		stringAmount = amount.toString();
	}

	if (!stringAmount.includes('.')) {
		return stringAmount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	}

	let [leading, trailing] = stringAmount.split('.');
	leading = leading.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	trailing = trailing.substr(0, length);
	return `${leading}.${trailing}`;
};

export const shorten = (str = '', amount = 8) => {
	return str.length > 20
		? `${str.substring(0, amount)}...${str.substring(str.length - amount)}`
		: str;
};

export const isClient = () => {
	return typeof document !== 'undefined';
};

export const useIntl = () => {
	const { formatMessage } = useIntlDefault();
	return translate.bind(null, formatMessage);
};
