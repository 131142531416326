import createStore from 'ctx-provider';
import { useToggle } from 'react-use';

const DEFAULT_DARK_MODE = true;

const useDarkmode = () => {
	const [darkmode, toggleDarkmode] = useToggle(DEFAULT_DARK_MODE);

	return {
		darkmode,
		toggleDarkmode,
	};
};

const store = createStore(useDarkmode);

export const { Provider } = store;
export default store.ctx;
