import { useState, useEffect, useContext } from 'react';
import createStore from 'ctx-provider';
import { isEqual } from 'lodash';
import { useMetamask } from '@energi/energi-wallet';
import { energiClient, ethereumClient } from 'apollo/client';
import { FETCH_DEPOSITS, SETTLED_TRANSFERS } from 'apollo/queries';
import latestBlockCtx from 'context/latestBlock';
import tokenlistCtx from 'context/tokenlist';
import { ALL_CHAINS, VALID_CHAINS } from 'utils/constants/chains';
import { DELAY_THRESHOLD, TRANSACTIONS_PER_PAGE } from 'utils/constants';

const useTransactionsHistory = () => {
	const { chainId, address, connected } = useMetamask();
	const { latestBlock } = useContext(latestBlockCtx);
	const [transactions, setTransactions] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [currentAddress, setCurrentAddress] = useState(address);
	const { assetsFetched } = useContext(tokenlistCtx);
	const blockHeight = latestBlock[chainId];
	const [txAmount, setTxAmount] = useState(TRANSACTIONS_PER_PAGE);

	const fetchTransactions = async amount => {
		try {
			const ethereumDepositCall = ethereumClient.query({
				query: FETCH_DEPOSITS(address ? address?.toString() : '', amount),
				fetchPolicy: 'no-cache',
			});

			const energiDepositCall = energiClient.query({
				query: FETCH_DEPOSITS(address ? address?.toString() : '', amount),
				fetchPolicy: 'no-cache',
			});

			const [ethereumResult, energiResult] = await Promise.all([
				ethereumDepositCall,
				energiDepositCall,
			]);

			const energiTransferIds = energiResult.data?.depositedTransfers?.map(
				item => item.id,
			);

			const ethereumTransferIds = ethereumResult.data?.depositedTransfers?.map(
				item => item.id,
			);

			const ethereumSettledCall = ethereumClient.query({
				query: SETTLED_TRANSFERS(energiTransferIds),
				fetchPolicy: 'no-cache',
			});

			const energiSettledCall = energiClient.query({
				query: SETTLED_TRANSFERS(ethereumTransferIds),
				fetchPolicy: 'no-cache',
			});

			const [settledOnEthereum, settledOnEnergi] = await Promise.all([
				ethereumSettledCall,
				energiSettledCall,
			]);

			const energiTransactions = [];
			const ethereumTransactions = [];

			// energi to ethereum
			energiResult.data?.depositedTransfers.forEach(txn => {
				const settled = settledOnEthereum.data.settledTransfers.find(
					item => item.id === txn.id,
				);
				const sourceNetwork = ALL_CHAINS.energi;
				const delayed =
					!settled &&
					latestBlock.energi - txn.blockNumber >
						sourceNetwork.confirmationCount +
							DELAY_THRESHOLD[sourceNetwork.chainId];
				energiTransactions.push({
					...txn,
					source: 'energi',
					destination: 'ethereum',
					settled,
					delayed,
				});
			});

			// ethereum to energi
			ethereumResult.data?.depositedTransfers.forEach(txn => {
				const settled = settledOnEnergi.data.settledTransfers.find(
					item => item.id === txn.id,
				);
				const sourceNetwork = ALL_CHAINS.ethereum;
				const delayed =
					!settled &&
					latestBlock.ethereum - txn.blockNumber >
						sourceNetwork.confirmationCount +
							DELAY_THRESHOLD[sourceNetwork.chainId];
				ethereumTransactions.push({
					...txn,
					source: 'ethereum',
					destination: 'energi',
					settled,
					delayed,
				});
			});

			if (
				!ethereumResult?.data?.depositedTransfers ||
				!energiResult?.data?.depositedTransfers
			) {
				setLoading(false);
				setTransactions([]);
				return;
			}

			if (
				energiTransactions.length === 0 &&
				ethereumTransactions.length === 0
			) {
				setTransactions([]);
				setLoading(false);
				return;
			}

			const result = [...energiTransactions, ...ethereumTransactions];

			// We need to make sure state updates are done when necessary
			if (!isEqual(result, transactions)) {
				setTransactions(result);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (err) {
			setTransactions([]);
			setLoading(false);
			setError(err);
		}
	};

	const loadTx = async (amount, fn = () => {}) => {
		setTxAmount(amount);
		fetchTransactions(amount).then(fn);
	};

	useEffect(() => {
		if (
			blockHeight &&
			assetsFetched &&
			connected &&
			VALID_CHAINS.includes(chainId)
		) {
			// showing loading for bridge history when account is changed!
			if (address !== currentAddress) {
				setLoading(true);
				setCurrentAddress(address);
			}
			fetchTransactions(txAmount);
		}

		if (!assetsFetched) {
			setLoading(true);
			setTransactions([]);
		}

		if (!address && !VALID_CHAINS.includes(chainId)) {
			setTransactions([]);
			setLoading(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address, blockHeight, assetsFetched, connected]);

	return {
		loading,
		error,
		transactions,
		fetchTransactions,
		loadTx,
	};
};

const store = createStore(useTransactionsHistory);

export const { Provider } = store;
export default store.ctx;
