import sdk from '@energi/energi-sdk';

const enviroment = process.env.GATSBY_APP_ENVIROMENT ?? 'testnet';
const { energi, ethereum } = sdk;

const config = {
	testnet: {
		ETHEREUM_CHAINID: ethereum.TESTNET_CHAINID,
		ENERGI_CHAINID: energi.TESTNET_CHAINID,
		GA_TRACKING_ID: '',
		ETHEREUM_SUBGRAPH:
			'https://eth.test.energi.network/http/subgraphs/name/energi/bridge',
		ENERGI_SUBGRAPH:
			'https://graph.test.energi.network/http/subgraphs/name/energi/bridge',
		ENERGISWAP_URL: 'https://app.test.energiswap.exchange/',
		BOUNTY_URL: 'https://wiki.energi.world/en/support/help-me',
		ANALYTICS_URL: 'https://info.test.energiswap.exchange/',
		EXCHANGES_URL: 'https://energi.world/get-nrg/',
		NEXUS_URL: 'https://nexus.test.energi.network/alerts',
	},
	mainnet: {
		ETHEREUM_CHAINID: ethereum.MAINNET_CHAINID,
		ENERGI_CHAINID: energi.MAINNET_CHAINID,
		GA_TRACKING_ID: 'G-PQ7TNL73JH',
		ETHEREUM_SUBGRAPH:
			'https://eth.energi.network/http/subgraphs/name/energi/bridge',
		ENERGI_SUBGRAPH:
			'https://graph.energi.network/http/subgraphs/name/energi/bridge',
		ENERGISWAP_URL: 'https://app.energiswap.exchange/',
		BOUNTY_URL: 'https://wiki.energi.world/en/support/help-me',
		ANALYTICS_URL: 'https://info.energiswap.exchange/',
		EXCHANGES_URL: 'https://energi.world/get-nrg/',
		NEXUS_URL: 'https://nexus.energi.network/alerts',
	},
}[enviroment];

export const TELEGRAM_URL = 'https://t.me/energiannouncements/';

export default config;
