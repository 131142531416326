import { gql } from '@apollo/client';

export const FETCH_DEPOSITS = (userAddress, amount) => {
	const queryString = `
    query depositedTransfers {
      depositedTransfers(
        first: ${amount}
        where: { from: "${userAddress}" }
        orderBy: blockNumber
        orderDirection: desc
      ) {
        id
        index
        cashier
        token
        coToken
        from
        to
        amount
        fee
        wrapped
        txHash
        blockNumber
        blockHash
        timestamp
      }
    }
  `;
	return gql(queryString);
};

export const SETTLED_TRANSFERS = (keys = []) => {
	const queryString = `
    query settledTransfers {
      settledTransfers(
        where: {id_in: ${JSON.stringify(keys)}}
      ) {
        id
        witnesses
        txHash
        blockNumber
        blockHash
        timestamp
      }
    }
  `;
	return gql(queryString);
};
