import 'styles/main.css';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, MuiThemeProvider, Seo } from '@energi/ui';
import GlobalContext from 'components/layout/GlobalContext';
import usePageMeta from 'hooks/usePageMeta';
import lightTheme from 'styles/light-theme';
import darkTheme from 'styles/dark-theme';
import darkmodeCtx from 'context/darkmode'; // in this stage, it is not yet bacome part of the ContextProvider
import useStyles from 'styles/components/layout/Layout';

const Layout = props => (
	<GlobalContext pageProps={props}>
		<LayoutInner {...props} />
	</GlobalContext>
);

const LayoutInner = ({ children, location }) => {
	const classes = useStyles();
	const { darkmode } = useContext(darkmodeCtx);
	const { pageTitle, pageDescription } = usePageMeta(location.pathname);
	const currentTheme = darkmode ? darkTheme : lightTheme;
	const meta = [
		{
			name: 'og:image',
			content:
				'https://s3.us-west-2.amazonaws.com/download.energi.software/thumbnails/bridge.png',
		},
		{
			name: 'image',
			property: 'og:image',
			content:
				'https://s3.us-west-2.amazonaws.com/download.energi.software/thumbnails/bridge.png',
		},
	];

	return (
		<MuiThemeProvider theme={currentTheme}>
			<CssBaseline />
			<Seo title={pageTitle} description={pageDescription} meta={meta} />
			<div className={classes.root}>{children}</div>
		</MuiThemeProvider>
	);
};

LayoutInner.propTypes = {
	location: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

export default Layout;
