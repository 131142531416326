import { useMemo, useState } from 'react';
import { useMetamask } from '@energi/energi-wallet';
import createStore from 'ctx-provider';
import {
	ENV_ENERGI_CHAINID,
	ENV_ETHEREUM_CHAINID,
} from 'utils/constants/chains';
import { VALID_CHAINS } from '../utils/constants/chains';

const useChains = () => {
	const [sourceChainId, setSourceChainId] = useState();
	const [destinationChainId, setDestinationChainId] = useState();

	const { chainId, connected } = useMetamask();

	useMemo(() => {
		if (chainId !== sourceChainId && VALID_CHAINS.includes(chainId)) {
			setSourceChainId(chainId);
			// determine the source and chain for setting the destination chain
			// the logic should change if we want to add more chains
			setDestinationChainId(
				chainId === ENV_ENERGI_CHAINID
					? ENV_ETHEREUM_CHAINID
					: ENV_ENERGI_CHAINID,
			);
		}

		if (!connected) {
			setSourceChainId(ENV_ENERGI_CHAINID);
			setDestinationChainId(ENV_ETHEREUM_CHAINID);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, connected]);

	return {
		sourceChainId,
		destinationChainId,
	};
};

const store = createStore(useChains);

export const { Provider } = store;
export default store.ctx;
