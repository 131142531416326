import { useEffect, useState } from 'react';
import sdk from '@energi/energi-sdk';
import { aggregate, createWatcher } from '@makerdao/multicall';
import { useMetamask } from '@energi/energi-wallet';
import Web3 from 'web3';
import createStore from 'ctx-provider';
import tokenDataEthereum from 'config/token-data-ethereum';
import tokenDataRinkeby from 'config/token-data-rinkeby';
import tokenDataEnergi from 'config/token-data-energi';
import tokenDataEnergiTestnet from 'config/token-data-energi-testnet';
import { getTokenlistContract, getTokenContract } from 'utils/get-contract';
import {
	BALANCE_FETCH_INTERVAL,
	ETHEREUM_HIDDEN_TOKENS,
} from 'utils/constants';
import TokenCalls from 'utils/calls';
import { VALID_CHAINS } from 'utils/constants/chains';

const {
	ZERO_ADDRESS,
	contracts: { TOKEN_CASHIER_ADDRESSES, MULTI_CALL_ADDRESSES },
	urls: { RPC_URLS },
} = sdk;

const NETWORK_TOKEN_ORDER = {
	1: [
		'0x0000000000000000000000000000000000000000', // ETH
		'0x1416946162b1c2c871a73b07e932d2fb6c932069', // NRG
		'0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
		'0x6982508145454ce325ddbe47a25d4ec3d2311933', // PEPE
		'0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT
		'0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
		'0x6b175474e89094c44da98b954eedeac495271d0f', // DAI
	],
	39797: [
		'0x0000000000000000000000000000000000000000', // NRG
		'0xa55f26319462355474a9f2c8790860776a329aa4', // WNRG
		'0x78b050d981d7f6e019bf6e361d0d1167de6b19da', // ETH
		'0x52b16632a9ed3977d7d701108bd548ce693b610c', // PEPE
		'0x470075cf46e6132aad78c40a1be53a494b05e831', // USDT
		'0xffd7510ca0a3279c7a5f50018a26c21d5bc1dbcf', // USDC
		'0x0ee5893f434017d8881750101ea2f7c49c0eb503', // DAI
	],
};

const sortTokens = (tokens, networkChainId) => {
	const tokenOrder = NETWORK_TOKEN_ORDER[networkChainId];
	return [...tokens].sort((a, b) => {
		const indexA = tokenOrder.indexOf(a.address.toLowerCase());
		const indexB = tokenOrder.indexOf(b.address.toLowerCase());
		if (indexA === -1 && indexB === -1) return 0;
		if (indexA === -1) return 1;
		if (indexB === -1) return -1;
		return indexA - indexB;
	});
};

/**
 * @developer this method getInitialTokenList(), will be called just once when the page initially loads.
 * @param {*} chainId
 * @returns all hardcoded token data depending on which blockchain the user is connected to.
 */
const getInitialTokenList = chainId => {
	const tokens = {
		1: tokenDataEthereum,
		4: tokenDataRinkeby,
		39797: tokenDataEnergi,
		49797: tokenDataEnergiTestnet,
	};

	if (!tokens[chainId]) return [];

	return tokens[chainId].map((token, index) => {
		const newToken = { ...token };
		newToken.id = index + 1; // adding id for tokens
		return newToken;
	});
};

/**
 * @developer this method getApprovedTokenList(), will be called just once when the page initially loads unless there is a change in address or chainId.
 * @param {*} chainId
 * @param {*} tokenList
 * @returns updated token list reduced by those tokens that are not active on the bridge
 */
const getApprovedTokenList = async chainId => {
	if (VALID_CHAINS.includes(chainId)) {
		const bridgedTokenlistContract = getTokenlistContract(chainId, false);
		const nativeTokenListContract = getTokenlistContract(chainId, true);
		const initialTokenList = getInitialTokenList(chainId);

		const offset = 0;
		const limit = 200;
		const bridgedList = await bridgedTokenlistContract.methods
			.getActiveItems(offset, limit)
			.call();
		const nativeList = await nativeTokenListContract.methods
			.getActiveItems(offset, limit)
			.call();

		let nonNativeTokens = [
			...new Set([...bridgedList?.items_, ...nativeList?.items_]),
		].filter(item => item !== ZERO_ADDRESS);
		const nativeCoin = initialTokenList[0];

		// applies to Ethereum mainnet
		if (chainId === 1) {
			nonNativeTokens = nonNativeTokens.filter(
				address => !ETHEREUM_HIDDEN_TOKENS.includes(address.toLowerCase()),
			);
		}

		const nonNativeTokenList = nonNativeTokens.map(tokenAddress => {
			const initialToken = initialTokenList.find(
				initialToken =>
					initialToken.address.toLowerCase() === tokenAddress.toLowerCase(),
			);

			if (initialToken) {
				return initialToken;
			}

			return {
				address: tokenAddress.toLowerCase(),
			};
		});
		return [nativeCoin, ...nonNativeTokenList];
	}
	return [];
};

const useTokenList = () => {
	const { chainId, address, connected } = useMetamask();
	const [token, setToken] = useState();
	const [activeWatchers, setActiveWatchers] = useState([]);
	const [fetchedStaticContent, setFetchedStaticContent] = useState(false);
	const [assetsFetched, setAssetsFetched] = useState(false);
	const [balanceFetched, setBalanceFetched] = useState(false);
	const [tokenList, setTokenList] = useState();
	const [approvedTokenList, setApprovedTokenList] = useState();
	const tokenListPrototype = {};
	VALID_CHAINS.forEach(chainId => {
		// showing the native coin when data not fetched
		tokenListPrototype[chainId] = [getInitialTokenList(chainId)[0]];
	});

	/**
	 * @developer this method manually checks for the token allowance
	 * @param {*} contractAddress
	 * @returns allowance value
	 */
	const tokenAllowanceCall = async contractAddress => {
		const contract = await getTokenContract(chainId, contractAddress);
		const result = await contract.methods
			.allowance(address, TOKEN_CASHIER_ADDRESSES[chainId])
			.call();
		return Web3.utils.toHex(result);
	};

	/**
	 * @developer this method updates the allowance value for the token
	 * @param {*} allowance
	 * * @param {*} targetToken
	 */
	const updateAllowance = (allowance, targetToken = null) => {
		const newToken = targetToken ?? token;
		newToken.allowance = allowance;
		setToken(newToken);
	};

	/**
	 * @developer this method checks for current selected token approval or allowance state
	 * @param {*} tokenId
	 */
	const checkCurrentTokenApproval = async tokenId => {
		const targetToken = tokenList[chainId].find(token => token.id === tokenId);
		if (targetToken) {
			const allowance = await tokenAllowanceCall(targetToken.address);
			updateAllowance(allowance);
		}
	};

	/**
	 * @developer this method setTokenId() is a flag for the current selected token
	 * @param {*} id
	 * @returns updated token list with ID
	 */
	const setTokenId = async id => {
		const newToken = tokenList[chainId].find(t => t.id === id);
		if (newToken) {
			// as a new Object, to force re-rendering
			setToken(newToken);
			if (newToken.address !== ZERO_ADDRESS && connected) {
				const allowance = await tokenAllowanceCall(newToken.address);
				updateAllowance(allowance, newToken);
			}
		}
	};

	// Stop and removes all the current active watchers
	const removeWatchers = () => {
		activeWatchers.forEach(watcher => {
			watcher?.stop();
		});

		setActiveWatchers([]);
	};

	// update the tokenList state for a new value for a single token
	const updateSingleToken = (updates, key, chainId) => {
		updates.map(update => {
			const tokenToBeEdited = tokenList[chainId].find(
				item => item.address === update.type,
			);
			if (tokenToBeEdited[key] !== update.value.toString()) {
				// update the state if there is a change
				tokenToBeEdited[key] = update.value.toString();
			}
			return tokenToBeEdited;
		});
	};

	/**
	 * @developer this method initializes multiple watchers that fetches data once the page loads,
	 * this requests will only be sent once and it stops the watcher after fetching the data
	 * @returns void
	 */
	const fetchTokensStaticData = () => {
		try {
			let counter = 0;
			VALID_CHAINS.forEach(async chainId => {
				const list = approvedTokenList[chainId];
				// removing the native coin from the token list (name, symbol, decimals doesn't need the native coin
				const nonNativeTokens = list.filter(
					item => item.address !== ZERO_ADDRESS,
				);

				const methods = TokenCalls[chainId];
				const config = {
					rpcUrl: RPC_URLS[chainId],
					multicallAddress: MULTI_CALL_ADDRESSES[chainId],
				};

				const calls = {
					name: aggregate(
						nonNativeTokens?.map(token =>
							methods.constructOtherCalls(token, 'name'),
						),
						config,
					),
					symbol: aggregate(
						nonNativeTokens?.map(token =>
							methods.constructOtherCalls(token, 'symbol'),
						),
						config,
					),
					decimals: aggregate(
						nonNativeTokens?.map(token =>
							methods.constructOtherCalls(token, 'decimals'),
						),
						config,
					),
					bridgeMin: aggregate(
						list?.map(token => methods.bridgeMinCall(token)),
						config,
					),
					bridgeMax: aggregate(
						list?.map(token => methods.bridgeMaxCall(token)),
						config,
					),
				};

				const keys = Object.keys(calls);
				const tokenData = await Promise.all(Object.values(calls));
				tokenData.forEach((res, index) => {
					const data = res.results.transformed;
					const formattedData = [];
					Object.keys(data).forEach(address => {
						formattedData.push({
							type: address,
							value: data[address],
						});
					});
					updateSingleToken(formattedData, keys[index], chainId);
				});
				counter += 1;
				if (counter === VALID_CHAINS.length) {
					setAssetsFetched(true);
				}
			});
		} catch (err) {
			throw new Error(`Unable to fetch tokens data: ${err}`);
		}
	};

	/**
	 * @developer this method initializes multiple watchers that needs to be constantly updated, specifically balance of tokens, that handle the initial multicall requests, and emits an events, if a change is detected in a users account
	 * Because multicall caches calls and response, this requests will only be sent once, and only when a change is detected
	 * @param {*} chainId
	 * @returns void
	 */
	const updateTokenBalances = async () => {
		const config = {
			rpcUrl: RPC_URLS[chainId],
			multicallAddress: MULTI_CALL_ADDRESSES[chainId],
			interval: BALANCE_FETCH_INTERVAL[chainId], // setting the interval for 1/2 block creation for each chain, 6s on Ethereum and 12s on Energi
		};

		// stops all current watchers
		removeWatchers();
		setBalanceFetched(false);

		const key = 'balance';

		try {
			const call = tokenList[chainId].map(token =>
				TokenCalls[chainId].balanceCall(token, address),
			);

			const watcher = createWatcher(call, config);
			watcher.batch().subscribe(updates => {
				updateSingleToken(updates, key, chainId);
				setBalanceFetched(true);
			});
			watcher.start();
			setActiveWatchers([...activeWatchers, watcher]);
		} catch (err) {
			throw new Error('Unable to fetch tokens balance: ', err);
		}
	};

	// returns native coin specification
	const getNativeCoin = chainId => {
		if (!VALID_CHAINS.includes(chainId)) {
			return null;
		}
		if (tokenList && tokenList[chainId].length) {
			return tokenList[chainId][0];
		}
		const [nativeCoin] = getInitialTokenList(chainId);
		return nativeCoin;
	};

	useEffect(() => {
		// fetching approved tokens from the contract when initialized
		const fetchAndSortTokens = async () => {
			try {
				const approvedTokensResult = await Promise.all(
					VALID_CHAINS.map(networkChainId =>
						getApprovedTokenList(networkChainId),
					),
				);
				const sortedApprovedTokenList = {};
				approvedTokensResult.forEach((approvedTokens, index) => {
					const chainId = VALID_CHAINS[index];
					sortedApprovedTokenList[chainId] = sortTokens(
						approvedTokens,
						chainId,
					);
				});
				setTokenList(sortedApprovedTokenList);
				setApprovedTokenList(sortedApprovedTokenList);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching tokens:', error);
				// Handle error appropriately (e.g., show user message)
			}
		};

		fetchAndSortTokens();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (approvedTokenList && tokenList && !fetchedStaticContent) {
			fetchTokensStaticData();
			setFetchedStaticContent(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [approvedTokenList, tokenList, fetchedStaticContent]);

	useEffect(() => {
		if (
			VALID_CHAINS.includes(chainId) &&
			assetsFetched &&
			connected &&
			tokenList
		) {
			setToken(tokenList[chainId][0]);
			updateTokenBalances(chainId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetsFetched, chainId, connected]);

	if (VALID_CHAINS.includes(chainId)) {
		return {
			tokenList: tokenList && assetsFetched ? tokenList : tokenListPrototype,
			token: token || getNativeCoin(chainId),
			setTokenId,
			checkTokenApproval: checkCurrentTokenApproval,
			getNativeCoin,
			assetsFetched,
			balanceFetched,
		};
	}

	// Wrong chain or network
	return {
		tokenList: [],
		balanceFetched,
		getNativeCoin,
	};
};

const store = createStore(useTokenList);

export const { Provider } = store;
export default store.ctx;
