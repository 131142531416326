import assets from '@energi/energiswap-default-token-list';
import sdk from '@energi/energi-sdk';
import ETHLogo from 'assets/tokens/ETH.svg';

const ENERGI_MAINNET_CHAINID = 39797;
const ethereumTokens = assets.tokens
	.filter(item => item.chainId === ENERGI_MAINNET_CHAINID && item.ethereum)
	.map(item => ({
		address: item.ethereum,
		icon: item.logo,
	}));

export default [
	// ETH - native coin - in native Token List (0), name, symbol, and decimals not available on chain
	// used as dummy address - native coin is not a smart contract
	{
		address: sdk.ZERO_ADDRESS,
		icon: ETHLogo,
		name: 'Ether',
		symbol: 'ETH',
		decimals: 18,
	},
	...ethereumTokens,
];
