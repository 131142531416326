import { useIntl } from 'utils/helper';

/**
 * Gets page title and description from translation
 * based on current url pathname.
 *
 * @param {string} pathname - page pathname
 * @returns {object}
 */
const usePageMeta = () => {
	const t = useIntl();

	const pageTitle = t('page.seo.title');
	const pageDescription = t('page.seo.description');

	return {
		pageTitle,
		pageDescription,
	};
};

export default usePageMeta;
