import { energiClient, ethereumClient } from 'apollo/client';
import sdk from '@energi/energi-sdk';
import config from 'config/config';
import { expand } from 'utils/helper';
import ETHLogo from '../../assets/tokens/ETH.svg';
import NRGLogo from '../../assets/tokens/NRG.svg';

export const ENV_ENERGI_CHAINID = parseInt(config.ENERGI_CHAINID, 10);
export const ENV_ETHEREUM_CHAINID = parseInt(config.ETHEREUM_CHAINID, 10);
export const VALID_CHAINS = [ENV_ENERGI_CHAINID, ENV_ETHEREUM_CHAINID];

const { energi, ethereum } = sdk;

export const ALL_CHAINS = expand([
	{
		keys: [ENV_ENERGI_CHAINID, 'energi'],
		value: {
			chainId: ENV_ENERGI_CHAINID,
			confirmationCount: energi.CONFIRMATION_COUNT,
			subgraph: energiClient,
			wrapped: energi.WRAPPED_TOKEN,
			icon: NRGLogo,
			heading: 'Energi',
			explorer: energi.EXPLORER,
			explorerUrl:
				process.env.GATSBY_APP_ENVIROMENT === 'mainnet'
					? energi.EXPLORER_URL_MAINNET
					: energi.EXPLORER_URL_TESTNET,
			transferTxField: 'energiTransferTx',
		},
	},
	{
		keys: [ENV_ETHEREUM_CHAINID, 'ethereum'],
		value: {
			chainId: ENV_ETHEREUM_CHAINID,
			confirmationCount: ethereum.CONFIRMATION_COUNT,
			subgraph: ethereumClient,
			wrapped: ethereum.WRAPPED_TOKEN,
			icon: ETHLogo,
			heading: 'Ethereum',
			explorer: ethereum.EXPLORER,
			explorerUrl:
				process.env.GATSBY_APP_ENVIROMENT === 'mainnet'
					? ethereum.EXPLORER_URL_MAINNET
					: ethereum.EXPLORER_URL_TESTNET,
			transferTxField: 'ethereumTransferTx',
		},
	},
]);

export const SUPPORTED_NETWORK = expand([
	{
		keys: [ENV_ENERGI_CHAINID, 'energi', 'ENERGI'],
		value: 'energi',
	},
	{
		keys: [ENV_ETHEREUM_CHAINID, 'ethereum', 'ETHEREUM'],
		value: 'ethereum',
	},
]);
