import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import sdk from '@energi/energi-sdk';
import { ENERGI_CHAINID, ETHEREUM_CHAINID } from 'utils/constants';

const ethereumBridgeSubgraph = sdk.urls.BRIDGE_SUBGRAPH_URL[ETHEREUM_CHAINID];
const energiBridgeSubgraph = sdk.urls.BRIDGE_SUBGRAPH_URL[ENERGI_CHAINID];

export const energiClient = new ApolloClient({
	link: new HttpLink({
		uri: energiBridgeSubgraph,
		fetch,
	}),
	cache: new InMemoryCache(),
});

export const ethereumClient = new ApolloClient({
	link: new HttpLink({
		uri: ethereumBridgeSubgraph,
		fetch,
	}),
	cache: new InMemoryCache(),
});
