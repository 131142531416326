import { createTheme, alpha } from '@energi/ui';
import defaultTheme from './default-theme';
import colors from './colors';

export default createTheme({
	...defaultTheme,
	palette: {
		...colors,
		type: 'light',
		text: {
			primary: '#212121',
			secondary: '#787878',
		},
		primary: {
			light: colors.green[100],
			main: colors.green[300],
			dark: colors.green[500],
			contrastText: colors.white,
		},
		secondary: {
			light: colors.grey[700],
			main: colors.black,
			dark: colors.grey[900],
			contrastText: colors.white,
		},
		error: {
			light: colors.red[400],
			main: colors.red[600],
			dark: colors.red[800],
			contrastText: colors.white,
		},
		warning: {
			light: colors.orange[300],
			main: colors.orange[500],
			dark: colors.orange[700],
			contrastText: colors.white,
		},
		// Use defaults for info
		// info: {},
		success: {
			light: colors.green[100],
			main: colors.green[300],
			dark: colors.green[500],
			contrastText: colors.white,
		},
		default: {
			light: colors.grey[100],
			main: colors.grey[300],
			dark: colors.grey[400],
			contrastText: alpha(colors.black, 0.87),
		},
	},
	overrides: {
		MuiIconButton: {
			root: {
				borderRadius: 6,
				'& .MuiTouchRipple-root *': {
					borderRadius: 6,
				},
			},
		},
		MuiOutlinedInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[800]} inset`,
					WebkitTextFillColor: colors.white,
				},
			},
		},
		MuiFilledInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[100]} inset`,
					WebkitTextFillColor: colors.grey[900],
				},
			},
		},
	},
});
