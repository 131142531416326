import config from 'config/config';

// These are the chains subgraph uses
export const ENERGI_CHAINID = parseInt(config.ENERGI_CHAINID, 10);
export const ETHEREUM_CHAINID = parseInt(config.ETHEREUM_CHAINID, 10);

// tokens on ethereum that should be hidden on mainnet
export const ETHEREUM_HIDDEN_TOKENS = [
	'0xb8c77482e45f1f44de1745f52c74426c631bdd52', // BNB
	'0x4f9254c83eb525f9fcf346490bbb3ed28a81c667', // CELR
	'0x2ba592f78db6436527729929aaf6c908497cb200', // CREAM
	'0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd', // DODO
	'0x6f259637dcd74c767781e37bc6133cd6a68aa161', // HT
	'0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3', // LEO
	'0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b', // NXM
	'0x75231f58b43240c9718dd58b4967c5114342a86c', // OKB
	'0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664', // QKC
	'0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202', // KNC
];

export const GAS_LIMIT = '50000000';
export const GAS_PRICE_MARGIN = 10; // 10% margin
export const GAS_UNIT_AMOUNT = '350000';

export const TRANSACTIONS_PER_PAGE = 20; // number of transactions per page (scroll pagination)

// number of additional blocks, after confirmation, before a transaction is considered delayed
export const DELAY_THRESHOLD = {
	1: 75,
	4: 75,
	39797: 60,
	49797: 60,
};

export const BALANCE_FETCH_INTERVAL = {
	// in miliseconds
	1: 6000,
	4: 6000,
	39797: 30000,
	49797: 30000,
};
