import React, { useMemo, useState } from 'react';
import createStore from 'ctx-provider';
import { useQueue } from 'react-use';
import { Modal } from '@energi/ui';
import { useMetamask } from '@energi/energi-wallet';
import { VALID_CHAINS } from 'utils/constants/chains';

const useModals = () => {
	const { add, remove, first } = useQueue();
	const [searchModal, setSearchModal] = useState(false);
	const [notInstalledMetamaskModal, setNotInstalledMetamaskModal] =
		useState(false);
	const { installed, chainId, connect, switchToNetwork } = useMetamask();
	const container = useMemo(() => {
		if (first) {
			const { content } = first;
			return (
				<Modal open onClose={first.onClose}>
					{content}
				</Modal>
			);
		}
		return null;
	}, [first]);

	const connectToMetamask = async () => {
		try {
			if (!installed) {
				setNotInstalledMetamaskModal(true);
			} else {
				await connect(true);
				if (!VALID_CHAINS.includes(chainId)) {
					switchToNetwork(VALID_CHAINS[0]);
				}
			}
		} catch (err) {
			console.error(err); // eslint-disable-line no-console
		}
	};

	return {
		container,
		add,
		remove,
		searchModal,
		setSearchModal,
		notInstalledMetamaskModal,
		setNotInstalledMetamaskModal,
		connectToMetamask,
	};
};

const store = createStore(useModals);

export const { Provider } = store;
export default store.ctx;
