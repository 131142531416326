import { useState, useEffect } from 'react';
import createStore from 'ctx-provider';

const key = 'new-transactions';
const initialValue = [];
const useLocalTransactions = () => {
	const [localTxn, setLocalTxn] = useState(() => {
		if (typeof window !== 'undefined' && window.localStorage) {
			const storageValue = localStorage.getItem(key);

			if (storageValue) {
				return JSON.parse(storageValue);
			}
		}

		return initialValue;
	});

	useEffect(() => {
		if (typeof window !== 'undefined' && window.localStorage) {
			localStorage.setItem(key, JSON.stringify(localTxn));
		}
	}, [localTxn]);

	return [localTxn, setLocalTxn];
};

const store = createStore(useLocalTransactions);

export const { Provider } = store;
export default store.ctx;
